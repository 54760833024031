import React from 'react'
// import { useEffect } from 'react'
// import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import '../../css/resultsTablesEtc.css'
import InnerHTML from 'dangerously-set-html-content'
// import { Link } from 'gatsby';


export default function BundesligaTable() {



    



  const html = `
      <div id="scoreaxis-widget-1ac3b" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/82?autoHeight=0&amp;inst=1ac3b" style="width:100%;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"1ac3b"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-1ac3b iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
          `

  const html2 = `
        <div id="scoreaxis-widget-1ac3b" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/82?autoHeight=0&amp;inst=1ac3b" style="width:100%;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"1ac3b"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-1ac3b iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
            `

 

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://www.scorebar.com/widget/";
  //   script.async = true;
  //   script.setAttribute('data-scorebar', '');
  //   document.getElementById('matchs-details-container').appendChild(script);

  // }, []);

  return (
    <Layout>
    <div>


      <h1 className='py-4 font-semibold dark:text-white'>Bundesliga</h1>
      <InnerHTML html={html} />

      <h1 className='py-4 font-semibold dark:text-white'>Bundesliga Top Players</h1>
      <InnerHTML html={html2} />

    
      {/* <div id="scoreaxis-widget-26dbc" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/8?removeBorders=0&amp;inst=26dbc" style="width:100%;border:none;transition:all 300ms ease"></iframe><script>{window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"26dbc"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-26dbc iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)})}</script></div> */}
  
    </div>
    </Layout>
  )
}

